import React, { useEffect, useMemo, PopUp } from "react";
import { RouteComponentProps, useLocation, useHistory, Redirect } from 'react-router-dom'
import { useEthers, getChainName } from '@usedapp/core';
import theme from "../../theme";
import Layout from "../../components/Layout";
import useToken from "../../hook/use-token.hook"
import NFTBlokyContent from "./NFTBlokyContent"
import { useForceUpdate } from "@chakra-ui/hooks";
import { Heading, Container, ChakraProvider, Spinner, Stack } from "@chakra-ui/react";
import "whatwg-fetch";
import nftBloky from "./scripts/game2";
import seedrandom from "../scripts/seedrandom"
import contracts from "../../contracts/"
import { updateStatement } from "typescript";
import { ok } from "assert";
import detectEthereumProvider from "@metamask/detect-provider";
const avatars_bottts_sprites = require('@dicebear/avatars-bottts-sprites');
const { createAvatar } = require('@dicebear/avatars');

const network = 'Polygon' //'Rinkeby'  //can be: Mainnet, Polygon, Binance
const urlLocalPath = 'http://localhost:4000'
const urlServerPath = 'https://playablenft-backend.onrender.com' //'https://playablenft.herokuapp.com' //"https://playablenft.herokuapp.com" limitless-earth-47291.herokuapp.com
var gameHtmlPath = 'https://playablenft-backend.onrender.com/NFTBloky/index.html' //'https://www.plaable.com/games/nftbloky/index.html'
const testnetPath = 'https://playablenfttestnet-mw2w.onrender.com' //"https://serene-oasis-94218.herokuapp.com"
const testnetGameHtml = 'https://testnet.plaable.com/games/NFTBloky/index.html'
var urlPath = urlServerPath  //urlLocalPath
var canVasWidth, canvasHeight

// need to do backend switch based on network
if (process.env.NODE_ENV === "development") { //when doing local testing from localhost
  //urlPath = 'https://limitless-earth-47291.herokuapp.com'
  urlPath = urlLocalPath
  //gameHtmlPath = 'https://limitless-earth-47291.herokuapp.com/NFTBloky/index.html'
  gameHtmlPath = 'https://playablenft-backend.onrender.com/NFTBloky/index.html' //'https://playablenft.herokuapp.com/NFTBloky/index.html'
}
if (process.env.NODE_ENV === "production") {
  //urlPath = 'https://limitless-earth-47291.herokuapp.com'
  //urlPath = "https://playablenft.plaiable.com"
  urlPath = 'https://playablenft-backend.onrender.com' //"https://playablenft.herokuapp.com"
  gameHtmlPath = 'https://playablenft-backend.onrender.com/NFTBloky/index.html' //'https://www.plaable.com/games/NFTBloky/index.html'
}

function NFTBloky() {
  const location = useLocation();
  const { wallet, nft, contract, nftName, chainId } = location.state || {};
  //const { chainId } = useEthers()
  /*const accounts = location.state.wallet;
  const wallet = location.state.wallet
  const nft = location.state.nft
  const contract = location.state.contract
  const nftName = location.state.nftName
  */
  const [token, authenticated] = useToken(wallet, nft, chainId)
  const [htmlContent, setHtmlContent] = React.useState(null)
  const [htmlLoaded, setHtmlLoaded] = React.useState(false)
  const [htmlElementReady, setHtmlElementReady] = React.useState(false)

  const svgAvatar = createAvatar(avatars_bottts_sprites, { seed: `${nftName}${nft}`, size: 200 })
  console.log(`svg seed: ${nftName}${nft}`)

  function updategameHtmlPath() {
    if (chainId !== undefined) {
      if (chainId !== 1 && chainId !== 137 && process.env.NODE_ENV !== "development") {
        urlPath = testnetPath
        gameHtmlPath = testnetGameHtml
      }
    }
  }
  useMemo(() => {
    /*if (chainId !== undefined) {
      if (chainId !== 1 && chainId !== 137 && process.env.NODE_ENV !== "development") {
        urlPath = testnetPath
        gameHtmlPath = testnetGameHtml
      }
    }*/
    updategameHtmlPath()
    console.log(`chainId: ${chainId} urlPath: ${urlPath} agaemHtml: ${gameHtmlPath}`)
    if (chainId !== undefined && htmlContent === null) {
      console.log('load html')
      loadGameHtml()
    }
  }, [chainId]);

  useEffect(() => {
    window.ethereum.on('chainChanged', (chainId) => {
      console.log(`new chainId: ${chainId}`)
      window.location = "/"
    })
    window.ethereum.on('networkChanged', (networkId) => {
      console.log(`new networkId: ${networkId}`)
      window.location = "/"
    })
    window.ethereum.on('accountsChanged', (account) => {
      console.log(`new account: ${account}`)
      window.location="/"
    })
  })

  console.log(`authenticated: ${authenticated} htmlLoaded: ${htmlLoaded} htmlElementReady: ${htmlElementReady}`)

  let clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    alert('Complete Cache Cleared')
  };

  function loadScriptWithCode(scriptText) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptText;
    document.body.appendChild(script);
  }

  function getColors(seed) {
    const random = seedrandom(seed);
    //const random = new Math.seedrandom(gameCustomizationParams.seed);

    var r = Math.floor(random() * 256);
    var g = Math.floor(random() * 256);
    var b = Math.floor(random() * 256);
    return [r, g, b]
  }

  let generateLevel = async (args/*width, height, numberOfPolyominos, sizeOfPolyominos, margin, mixed, mode*/) => {
    let param = {
        width: args[0],
      height: args[1],
      nPoly: args[2],
      nSize: args[3],
      margin: args[4],
      mixed: args[5],
      mode: args[6]
    }
    //console.log(`${args} ${param}`)
    const res = await fetch(
      `${urlPath}/api/game/nftbloky/create-level/${contract}/${nft}/${JSON.stringify(param)}`,
      {
        headers: {
          'Authorization': token,
          //'Content-Type': 'application/json'
        },
        //body: JSON.stringify(param)
      }
    );
    //console.log(`createLevel:${res}`)
    let result = await res.json()
    if (res.status != 200)
      return {error: 'Status not 200', result}
    return result
  }

  let updateGameState = async (args) => {
    let param = {
      gameMode: args[0],
      pieces: args[1],
      bag: args[2],
      timeDiff: args[3],
      timestamp: Date.now(),
      timeOut: [args[4]]
    }
    const res = await fetch(
      `${urlPath}/api/game/nftbloky/game-states/${contract}/${nft}`,
      {
        method: 'post',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(param)
      }
    );
    //console.log(`createLevel:${res}`)
    let result = await res.json()
    return result
  }

  function loadGame() {
    console.log('loading nftBloky')
    let gameParams = {
      wallet: wallet,
      network: network,
      contract: contracts.getContract(network,'PlayableNFTSeries1Address'),
      nft: nft,
      avatar: svgAvatar
    }

    nftBloky.nftBloky(gameParams, async (action, ...args) => {
      //console.log('callback from the game here ' + action)
      if (action === 'getColors') {
        canVasWidth = args[0]
        canvasHeight = args[1]
        return getColors(nft)
      } else if (action === 'generateLevel') {
        return generateLevel(args)
      } else if (action === 'getGameState') {
        return getGameState()
      } else if (action === 'updateState') {
        return updateGameState(args) 
      }
    })
  }

  async function getGameState() {
    const res = await fetch(
      `${urlPath}/api/game/nftbloky/game-states/${contract}/${nft}`,
      {
        headers: {
          'Authorization': token
        },
      }
    );
    //console.log(`createLevel:${res}`)
    let result = await res.json()
    return result
  }

  function loadGameHtml() {
    // fetch the HTML fragment with a local API request
    fetch(gameHtmlPath, { mode: 'cors' })  //${this.props.content  for production: http://plaable.com/games/NFTBloky/index.html
              // for local test: https://playablenft.herokuapp.com/NFTBloky/index.html
      .then(resp => {
        // fetch returns a readable stream, so translate it into stringified HTML
        return resp.text();
      })
      .then(content => {
        // dangerouslySetInnerHTML requires using an object with an `__html` key
        console.log('html loaded. set setHtmlLoaded true')
        setHtmlContent(content);
        setHtmlLoaded(true)
        //this.clearCacheData()
        //this.loadScript()
        //loadGame()
        //forceUpdate()
      })
      .catch(err => {
        // handle the error
      });    
  }
  function getHtml() {
    console.log('getHtml')
   if (!htmlElementReady) {
     console.log('set htmlElementReady true')
     setHtmlElementReady(true)
    }
    return {
      __html: htmlContent
    }
  }
  //clearCacheData()
  //useForceUpdate()

  useEffect(() => {
    // a test
    // utils.dec_to_hex_string(10,1)
    // utils.rgba_to_hex_string(10,10,10)
    //window.location.href = "http://playablenft.herokuapp.com/NFTBloky/wallet=&contract=&nft=";
    //console.log(`update the page for new nft: ${location.state.nft}`)

    // get authenticated
    console.log('in useEffect')
    if (htmlContent !== null && authenticated && htmlElementReady) {
      console.log('in useEffect: loadGame')
      loadGame()
    }
  }, [htmlLoaded, authenticated, htmlElementReady]);

  if (location === null || location === undefined || location.state === null || location.state === undefined) {
    return (

      < Redirect to='/' >
        Please connect to your wallet
      </Redirect >

    );
  }

  /*function handleNewNetwork(networkId) {
    //networkDiv.innerHTML = networkId;
  }

  function handleNetworkChange(networkId) {
    console.log(`new networkId: ${networkId}`)
  }

  function handleChainChange(chainId) {
    console.log(`new chainId: ${chainId}`)
  }

  function handleAccountChange(account) {
    console.log(`new account: ${account}`)
  }*/

  return (
    <>
      <ChakraProvider>
      {
        location === undefined || location.state === undefined || location.state.wallet === undefined || location.state.nft === undefined ?
          <div>
            <Redirect to='/'>
              Please connect to your wallet
            </Redirect>
          </div>
          :
          <div></div>
        }
        <Container maxW={'5xl'}>
          <Stack align={'center'} spacing={{ base: 8, md: 10 }}
            py={{ base: 40, md: 50 }}>
        <Spinner size='xl'  />
        {
            authenticated?
          
          //<NFTBlokyContent wallet={location.state.wallet} token={token} nft={location.state.nft} contract={location.state.contract} nftName={location.state.nftName}/>
          <div dangerouslySetInnerHTML={getHtml()}></div> 
          :
                <Heading textColor={'orange.400'}
                  fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                  lineHeight={'110%'}>
                Authenticating...</Heading>
          }
          </Stack>
          </Container>
      </ChakraProvider>
    </>
     );
}

export default NFTBloky;