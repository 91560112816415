import {
    Heading,
    Text,
    Box,
  } from '@chakra-ui/react';

  type Props = {
    title: any;
    desc: any;
    rest?: any;
  };
  
export default function FeatureTextBox({ title, desc, ...rest }: Props) {
  return (
    <Box p={5} shadow="md" borderWidth="1px" {...rest}>
      <Heading fontSize="xl">{title}</Heading>
      <Text mt={4}>{desc}</Text>
    </Box>
  )
}
