import { useEffect, useReducer } from 'react'
import { defaultReducer } from './defaultReducer'
import { ethers, Contract } from "ethers";
import { useEthers, useContractCall } from "@usedapp/core";
import crestureContractAbi from  "../abi/Creature.json" //"../abi/ERC721.json";
import DehorizonMMONFT1Abi from  "../abi/DehorizonMMONFT1.json" //"../abi/ERC721.json";
import { CreatureContractAddress, DehorizonMMO1Address } from "../contracts"

const creatureContractInterface = new ethers.utils.Interface(crestureContractAbi.abi);
const contract = new Contract(CreatureContractAddress, crestureContractAbi.abi);

const DehorizonMMO1Interface = new ethers.utils.Interface(DehorizonMMONFT1Abi.abi);
const contractDehorizonMMO1 = new Contract(DehorizonMMO1Address, DehorizonMMONFT1Abi.abi);

export default function useNFT(id) {
  var nftId = id  
  var result
  const [state, dispatch] = useReducer(defaultReducer, {
    loading: true,
    error: false,
    data: []
  })

  useEffect(() => {
    result = OwnerOf(nftId);
    //eslint-disable-next-line 
  }, [nftId])

  const OwnerOf = async (nftId) => {
    dispatch({ type: 'PROCESSING' })

    try {
        const [result] = await useContractCall({
            abi: DehorizonMMO1Interface,
            address: DehorizonMMO1Address,
            method: "ownerOf",
            args: [nftId],
          }) ?? [];        
      dispatch({ type: 'SUCCESS', payload: result })
      return result
    } catch (err) {
      dispatch({ type: 'ERROR' })
      console.log(err)
    }
  }

  return result
  /*{
    ...state,
    OwnerOf
  }*/
}
