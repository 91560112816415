import React, { useEffect, useRef, useState } from 'react'
import { useParams, Redirect } from "react-router-dom";
import NavBar from "../components/NavBar";
import "@fontsource/inter";
import theme from "../theme";
import Layout from "../components/Layout";

import {
    ChakraProvider,
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Button,
    Input,
    HStack,
    VStack,
    Box,
    Icon,
    IconProps,
    List,
    ListItem,
    Link,
    Image,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Spinner,
} from '@chakra-ui/react';
import { useNameOfNFT} from '../hook/use-owner-nft.hook'
import NFTView from './NFTView';
const urlLocalPath = 'http://localhost:4000'
const urlServerPath = 'https://playablenft-backend.onrender.com' //"https://playablenft.herokuapp.com" //'https://limitless-earth-47291.herokuapp.com' //
const testnetPath = 'https://playablenfttestnet-mw2w.onrender.com' //"https://serene-oasis-94218.herokuapp.com"
var urlPath = urlServerPath//urlLocalPath
var video_url = ''

if (process.env.NODE_ENV === "development") {
    urlPath = urlLocalPath
}
if (process.env.NODE_ENV === "production") {
    urlPath = urlServerPath
}

export default function NFTPage() {
    const { id } = useParams();
    const [ nftMetaLoaded, setNftDataLoaded ] = React.useState(false)
    const [nftMeta, setNftMeta] = React.useState()
    const [videoUrl, setVideoUrl] = React.useState()
        
    useEffect(() => {
        getNftMeta(id)
    },[id])

    async function getNftMeta(id) {
        const res = await fetch(`${urlPath}/api/nft/PlayableNFTSeries1/${id}`)
        console.log(`${res}`)
        let result = await res.json()
        if (res.status != 200)
            return { error: 'Status not 200', result }
        //console.log(`nft meta data: ${JSON.stringify(result)}`)
        setNftMeta(result)
        setNftDataLoaded(true)
        //video_url = nftMeta.animation_url.substring(0, nftMeta.animation_url.length-4) + '.mp4'
        video_url = `https://plaable.com/nft/playablenftseries1/PlayableNFTSeries1${id}game.mp4`
        setVideoUrl(`https://plaable.com/nft/playablenftseries1/PlayableNFTSeries1${id}game.mp4`)
        console.log(`${video_url}`)
        return result
    }
    function getAvatarUrl() {
        if (nftMeta === undefined || nftMeta === null) {
            return '/logo.png'
        }
        let index = nftMeta.image.lastIndexOf('.')
        //console.log(`url: ${nftMeta.image}  ${index}`)
        let url = nftMeta.image.slice(0, index) + '.png'
        //console.log(`${url}`)
        return url
    }

    return (
        <ChakraProvider theme={theme}>
            <Layout>
                <NavBar />
                <Container maxW={'5xl'}>
            <Stack
                textAlign={'center'}
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                        py={{ base: 15, md: 10 }}>
                        {id === undefined ?
                            <>
                                <Heading
                                    fontWeight={600}
                                    fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                                    lineHeight={'110%'}>
                                    < Redirect to='/' >
                                        NFT Not Found {' redirecting'}
                                    </Redirect >
                    
                                </Heading>
                                <Box boxSize="sm">
                                    <Image src="logo.svg" alt="test" />
                                </Box>
                            </>
                            : <></>
                }
                { nftMetaLoaded ?
                            <>
                                <NFTView metaData={nftMeta}/>
                                <Heading
                    fontWeight={600}
                    fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                    lineHeight={'110%'}>
                    <Text as={'span'} color={'orange.400'}>{nftMeta.name}</Text>
                </Heading>
                                <Text as={'span'} textAlign={'left'}>
                                    {nftMeta.description}
                                </Text>
                                <video autoPlay loop muted playsInline width={540} height={960}>
                                    <source src={videoUrl} type="video/mp4" />                                    
                                    <a href={nftMeta.animation_url} target="_blank" rel="noopener"
                                    >See the gif here</a>
                                </video>
                                {/*<Image src={nftMeta.animation_url} width={540} height={960}/>*/}
                            </>
                            :
                            <Spinner size='xl' />
            } 
            </Stack>
                </Container >
            </Layout>
        </ChakraProvider>
    )
}
        