import React from 'react';
import Carousel from 'framer-motion-carousel';
const images = [
  '/NFTBloky0.png',
  '/NFTBloky1.png',
  '/PlayableNFTDemoGameScreen.png']

const NFTCarousel = () => (
  <Carousel interval={3000}>
    <img src={images[0]} />
    <img src={images[1]} />
    <img src={images[2]} />
  </Carousel>
);

export default NFTCarousel;
