import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';

const getWeb3 = async () => {

   const provider = await detectEthereumProvider();

  return new Promise((resolve, reject) => {
    if(provider !== null) {
        let web3 = new Web3(provider);
        resolve(web3);
      } else {
        reject("No web3 instance injected, make sure MetaMask is installed.");
      }    
  });

  /*return new Promise((resolve, reject) => {
    window.addEventListener("load", () => {
      let web3 = window.web3;

      if (typeof web3 !== "undefined") {
        web3 = new Web3(web3.currentProvider);
        resolve(web3);
      } else {
        reject("No web3 instance injected, make sure MetaMask is installed.");
      }
    });
  });*/

}

export default getWeb3;
