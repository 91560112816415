import React, { useEffect } from 'react'
import getWeb3 from "../getWeb3";
import { isMobileBrowser } from '../util/util';
import { ethers } from 'ethers'
import { useEthers, getChainName } from '@usedapp/core';

const urlLocalPath = 'http://localhost:4000'
const urlServerPath = 'https://playablenft-backend.onrender.com' //"https://playablenft.herokuapp.com" //'https://limitless-earth-47291.herokuapp.com' //
const testnetPath = 'https://playablenfttestnet-mw2w.onrender.com' //"https://serene-oasis-94218.herokuapp.com"
var urlPath = urlServerPath//urlLocalPath

if (process.env.NODE_ENV === "development") {
    urlPath = urlLocalPath
}
if (process.env.NODE_ENV === "production") {
    urlPath = urlServerPath
}
var signingFunction = "eth_signTypedData"
if (isMobileBrowser()) {
    signingFunction = 'personal_sign'
}

export default function useToken(wallet,nft, chainId) {
    const [challenge, setChallenge] = React.useState(null)
    const [signature, setSignature] = React.useState(null)
    //const { chainId } = useEthers()

    if (chainId !== 1 && chainId !== 137 && process.env.NODE_ENV !== 'development') {
        urlPath = testnetPath
        console.log(`urlpath: ${urlPath}`)
    }
    async function getChallenge() {
        const web3 = await getWeb3();
        const res = await fetch(
            `${urlPath}/auth/${wallet.toLowerCase()}/${isMobileBrowser()}`
        );
        let response = await res.json()
        setChallenge(response)
        console.log('received challenge, and asking user to sign')
        if (!isMobileBrowser()) {
            web3.currentProvider.sendAsync(
                {
                    method: "eth_signTypedData", //"eth_signTypedData",
                    params: [response, wallet],
                    from: wallet
                },
                (error, res) => {
                    if (error) return console.error(error);
                    setSignature(res.result)
                }
            );
        } else {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            //await window.ethereum.enable()
            const signer = provider.getSigner(wallet)
            // not working with imtoken mobile wallet

            //const address = await signer.getAddress()
            /*console.log('Calling ethereum signing request...')
            const ethereum = window.ethereum
            try {
            const sign = await ethereum.request({
                method: 'eth_signTypedData',
                params: [response, wallet],
            });
            console.log(`sign:${sign}`)
        } catch (err) {
            console.error(err);
            console.log(`Error: ${err.message}`)
        }*/

            const result = await signer.signMessage(response[1].value)
            console.log('mobile signing using imtoken')
            setSignature(result)
        }
    }    
    useEffect(() => {
        if (challenge === null) {
            console.log("get challenge")
            getChallenge()
        } 

    }, [wallet, nft])

    const [token, setToken] = React.useState(null)
    const [authenticated, setAuthenticated] = React.useState(false)
    async function getToken() {
        const res = await fetch(
            `${urlPath}/auth-sign/${challenge[1].value}/${signature}/${isMobileBrowser()}`
        );
        // response format:
        // success: true, token: 'JWT ' + token, recovered: wallet
        const response = await res.json();
        if (res.status === 200 && response.success) {
            setToken(response.token)
            setAuthenticated(true)
            console.log("Signature verified");
        } else {
            console.log("Signature not verified");
        }
    }
    useEffect(() => {
        if (signature !== null) {
            getToken()
        }

    }, [signature])

    return [token, authenticated]
}
