import React, { useEffect } from 'react'
import { Location } from 'history';
import {RouteComponentProps, useLocation, useHistory, Redirect} from 'react-router-dom'
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import Layout from "./components/Layout";
import { isMobileBrowser} from './util/util';

import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Button,
    Input,
    HStack,
    VStack,
    Box,
    Icon,
    IconProps,
    List,
    ListItem,
    Link
  } from '@chakra-ui/react';

  type stateType = {
    wallet: string, nft: string,  contract: string, nftName: string, chainId: number
};

export default function PlayNFT() {
    let history = useHistory()
    const { state } = useLocation<stateType>();
    console.log(state)
    //const { state } = props.location

    return (
        <ChakraProvider theme={theme}>
        <Layout>  
        <Container  maxW={'5xl'}>
                    {state === undefined ||
                        state.wallet === undefined || state.contract === undefined
                        || state.nft === undefined ?
                        <Text> Please connect to your wallet and make sure you own the PlayableNFT</Text>
                        :
                        <Text></Text>
            //window.location.href = './game/NFTBloky/index.html'
            //()=> { history.push('./game/NFTBloky/index.html')
                    }
                    
            {
                isMobileBrowser() ?
                            <Redirect to={{ pathname: "/NFTBlokyMobile", state: { wallet: state.wallet, nft: state.nft, contract: state.contract, nftName: state.nftName, chainId: state.chainId } }} />
            :
                            <Redirect to={{ pathname: "/NFTBloky", state: { wallet: state.wallet, nft: state.nft, contract: state.contract, nftName: state.nftName, chainId: state.chainId } }} />
            //<Button>Play the game</Button>
            //}
            }
        </Container>
        </Layout>
        </ChakraProvider>
    )
} 