import { ChakraProvider, useDisclosure, HStack, list, Grid } from "@chakra-ui/react";
import theme from "../theme";
import Layout from "../components/Layout";
import "@fontsource/inter";
import NavBar from "../components/NavBar";
import Typewriter from 'typewriter-effect';
import NFTCarousel from "./Carousel";
import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    List,
    ListItem,
    ListIcon,
    SimpleGrid,
    Button,
    Input,
    VStack,
    Box,
    Icon,
    IconProps,
    Link,
    Image
} from '@chakra-ui/react';

import {
    CheckCircleIcon,
    ArrowForwardIcon,
} from '@chakra-ui/icons';
import FeatureTextBox from "./FeatureTextBox";

const imageOne = '/logo.png'

   /* < Carousel
plugins = {
    [
    'infinite',
    {
        resolve: autoplayPlugin,
        options: {
            interval: 2000,
        }
    },
                    ]}
animationSpeed = { 1000}
    >
    <img src={imageOne} />
                </Carousel >
                */

function LearnMore() {
    return (
        <ChakraProvider theme={theme}>
            <Layout>
                <NavBar />
                <Container maxW={'10xl'}>
                    <Stack
                        textAlign={'center'}
                        align={'center'}
                        spacing={{ base: 8, md: 10 }}
                        py={{ base: 40, md: 48 }}>
                        <Heading
                            fontWeight={600}
                            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                            lineHeight={'110%'}>
                            Playable NFTs are{' '}
                        </Heading>
                        <Box fontWeight={600} fontSize={{ md: '4xl' }} color={'orange.400'}>
                        <Typewriter
                            options={{
                                    strings: ['NFT Collectibles', 'the Game itself is a NFT', 'For Owners ONLY', 'Unlimited Levels','Play-to-earn Cryptos'],
                                autoStart: true,
                                loop: true,
                            }}
                            />
                        </Box>
                        <Stack textAlign={'left'} width='100%' align={'center'}>
                            <List fontSize={{ md: '2xl' }} spacing={5} mb={10} mt={10} alignItems={true}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color='orange.400' />
                                A NFT that is an endless game by itself with unlimited levels
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color='orange.400' />
                                Only owner of the NFT can play the game
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color='orange.400' />
                                Every Playable NFT has its unique avatar
                            </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color='orange.400' />
                                    Every Playable NFT has its unique color scheme
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color='orange.400' />
                                    Earn PlayableNFT crypto tokens by playing the game*
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color='orange.400' />
                                        PlayableNFT token to be governed by DAO
                                    </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color='orange.400' />
                                    Earn mining capability for PlayableNFT tokens by playing the game*
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color='orange.400' />
                                        Total number of Playable NFT to be issued is limited
                                    </ListItem>

                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color='orange.400' />
                                    Trade your PlayableNFT on OpenSea to other collectors for a profit
                                    </ListItem>

                                    <ListItem>
                                        <Text ml={20} fontSize={{ md: '2xl' }}>* Coming soon!</Text>
                                </ListItem>
                            </List>
                            <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' mt={10} mb={20}>
                                <NFTCarousel>
                                </NFTCarousel>
                            </Box>

                        </Stack>
                        
                        <Heading fontWeight={600}
                            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                            lineHeight={'110%'}
                            align={'left'}
                        >
                            Roadmap{' '}
                        </Heading>
                        <Stack textAlign={'left'} width='70%'>
                            <FeatureTextBox title='2022 Q1' desc='Drop NFT Bloky puzzle game with built-in token earning and mining capability. Reach concensors on PlayableNFT token issurance with the crypto community.' borderRadius='lg' >
                        </FeatureTextBox>
                            <FeatureTextBox title='2022 Q2' desc='Issue PlayableNFT ERC20 tokens to game owners who have eared the tokens in playing Bloky game.' borderRadius='lg'>
                            </FeatureTextBox>
                            <FeatureTextBox title='2022 Q3' desc='Drop next PlayableNFT games to the community. This will be a totally different game from Season 1.' borderRadius='lg'>
                            </FeatureTextBox>
                            <FeatureTextBox title='2022 Q4' desc='Activate the mining capabilities of the NFT for PlayableNFT token mining for the game players. Continue work with the community on future plans for PlayableNFT and its token.' borderRadius='lg'>
                            </FeatureTextBox>
                        </Stack>
                    </Stack>
                </Container>
            </Layout>
        </ChakraProvider>
    );
}

export default LearnMore;
