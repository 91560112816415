import {
    ChakraProvider, useDisclosure,
    Container, HStack, Heading, Text,
    Stack
} from "@chakra-ui/react";
import theme from "../theme";
import Layout from "../components/Layout";
import "@fontsource/inter";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import TeamCard from "./TeamCard";

const teamMemberProps = [
    {
        name: 'Catherine',
        description: 'Social media and crypto community liason',
        image: '/img/team/cat3.png',
        responsibility: 'marketing',
        tag: ['twitter','nft','social','community']
    },
    {
        name: 'Jeanette',
        description: 'Social media and social campaign advisor',
        image: '/img/team/jeanette.png',
        responsibility: 'social',
        tag: ['social', 'nft', 'advisor', 'campaign']
    },
    {
        name: 'Chelsea',
        description: 'Image & editing, social media and YouTube',
        image: 'https://images.unsplash.com/photo-1520810627419-35e362c5dc07?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
        responsibility: 'art & social',
        tag: ['design', 'video', 'art', 'social']
    },
    {
        name: 'David',
        description: 'Game design and creation, NFT and crypto projects',
        responsibility: 'technology',
        image: '/img/team/david.png',
        tag: ['game', 'nft', 'crypto', 'blockchain']
    }
]


function Team() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <ChakraProvider theme={theme}>
            <Layout>
                <NavBar />
                <Container maxW={'5xl'}>
                    <Stack
                        textAlign={'center'}
                        align={'center'}
                        spacing={{ base: 8, md: 10 }}
                        py={10 }>
                        <Heading fontWeight={600}
                            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                            lineHeight={'110%'}>Mission</Heading>
                    <Text>Bring mini games to NFT world for fun and play-to-earn</Text>
                    <HStack p={5}>
                    {teamMemberProps.map((key, item) => {
                        return (
                            <TeamCard name={key.name} image={key.image} description={key.description} tag={key.tag} responsibility={key.responsibility} />
                        )
                    })}
                        </HStack>
                       </Stack>
                </Container>
                <Footer />
            </Layout>
        </ChakraProvider>
    )
}
export default Team;