import {
    Box,
    Center,
    useColorModeValue,
    Heading,
    Text,
    Stack,
    Image,
    md,
    HStack,
    AddIcon,
    TagLabel,
    Tag,
    TagLeftIcon
} from '@chakra-ui/react';
import { meta } from '@dicebear/avatars-bottts-sprites';

export default function NFTView({ metaData }) {
    console.log(`${JSON.stringify(metaData)}`)
    const bgColor = useColorModeValue('white', 'gray.800')
    const colorMap = {
        'bgColor': metaData.attributes.gameMainColor.bgColor,
        'planetColor': metaData.attributes.gameMainColor.planetColor,
        'sunColor': metaData.attributes.gameMainColor.sunColor,
        'smallPlanetColor': metaData.attributes.gameMainColor.smallPlanetColor,
        'bagColor': metaData.attributes.gameMainColor.bagColor
    }
    function getAvatarUrl() {
        if (metaData === undefined || metaData === null) {
            console.log(`metaData is undefined`)
            return '/logo.png'
        }
        let index = metaData.image.lastIndexOf('.')
        console.log(`url: ${metaData.image}  ${index}`)
        let url = metaData.image.slice(0, index) + '.svg'
        console.log(`${url}`)
        return url
    }

    return (
    <>
        { metaData !== undefined ?
            <>
                <Center py={-20}>
                    <Box
                        role={'group'}
                        p={6}
                        maxW={'530px'}
                        w={'full'}
                        bg={bgColor}
                        boxShadow={'2xl'}
                        rounded={'lg'}
                        pos={'relative'}
                        zIndex={1}>
                        <Box
                            rounded={'lg'}
                            mt={-12}
                            pos={'relative'}
                            height={'430px'}
                            _after={{
                                transition: 'all .3s ease',
                                content: '""',
                                w: 'full',
                                h: 'full',
                                pos: 'absolute',
                                top: 5,
                                left: 0,
                                backgroundImage: `url(${getAvatarUrl()})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                filter: 'blur(15px)',
                                zIndex: -1,
                            }}
                            _groupHover={{
                                _after: {
                                    filter: 'blur(20px)',
                                },
                            }}>
                            <Image
                                rounded={'lg'}
                                height={430}
                                width={482}
                                objectFit={'cover'}
                                src={getAvatarUrl()}
                            />
                        </Box>
                            <Stack pt={20} align={'center'}>
                                <Tag size={'md'} borderRadius='full'><TagLabel>Game Main Color:</TagLabel></Tag>
                                <HStack spacing={4}>
                                    {['bgColor', 'planetColor', 'sunColor', 'smallPlanetColor','bagColor'].map((color) => (
                                        <Text as={'span'} fontSize={'md'} fontWeight={700} color={colorMap[color]} key={color}>
                                            {color}
                                        </Text>
                                    ))}
                                </HStack>
                                <Tag size={'md'} borderRadius='full'><TagLabel>NFT Color Scheme:</TagLabel></Tag>
                                <HStack spacing={4}>
                                    {metaData.attributes.colorScheme.map((color) => (
                                        <Box bgColor={color} width={'30px'} height={'15px'} key={color}/> 
                                    ))}
                                </HStack>
                        </Stack>
                    </Box>
                </Center>
            </>
            : <></>
            }
            </>
    );
}