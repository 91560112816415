import {
    Box,
    chakra,
    Container,
    Link,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
    Image,
    Heading,
} from '@chakra-ui/react';
import { FaDiscord, FaInstagram, FaTelegram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { ReactNode } from 'react';

const Logo = (props: any) => {
    return (
        <Image src='/logo.png' />
    );
};

const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode;
    label: string;
    href: string;
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

export default function SmallCentered() {
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
              <Container
                as={Stack}
                maxW={'6xl'}
                py={4}
                spacing={4}
                justify={'center'}
                align={'left'}>
                <Stack direction={'row'} spacing={6}>
                    <Heading fontSize={'l'} verticalAlign={'center'} pt={1}>PlableNFT</Heading>
                    <Link href={'/learnmore'}>Learn More</Link>
                    <Link href={'#'}>FAQ</Link>
                    <Link href={'/howtoplay'}>How To Play</Link>
                    {/* <Link href={'/team'}>Team</Link> */}
                </Stack>
            </Container>
    
            <Box
                borderTopWidth={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.700')}>
                <Container
                    as={Stack}
                    maxW={'6xl'}
                    py={4}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={4}
                    justify={{ base: 'center', md: 'space-between' }}
                    align={{ base: 'center', md: 'center' }}>
                    <Text>© 2022 PlayableNFT All rights reserved</Text>
                    <Stack direction={'row'} spacing={6}>
                        <SocialButton label={'Twitter'} href={'https://twitter.com/nft_playable'}>
                            <FaTwitter />
                        </SocialButton>
                        <SocialButton label={'Discord'} href={'https://discord.gg/QFYv2SVu'}>
                            <FaDiscord />
                        </SocialButton>
                        <SocialButton label={'Telegram'} href={'https://t.me/playablenft'}>
                            <FaTelegram />
                        </SocialButton>
                        <SocialButton label={'YouTube'} href={'https://www.youtube.com/channel/UCsdVPfIHllxlUFj5CE_6m_A'}>
                            <FaYoutube />
                        </SocialButton>
                        <SocialButton label={'Instagram'} href={'https://www.instagram.com/playablenft/'}>
                            <FaInstagram />
                        </SocialButton>
                        
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
}