// a react component to inject the returned html
import React, { Component } from "react";
import { isPropertyAccessOrQualifiedName } from "typescript";
import "whatwg-fetch";
import nftBloky from "./scripts/game2";
import seedrandom from "../scripts/seedrandom"
import contracts from "../../contracts/"
const avatars_bottts_sprites = require('@dicebear/avatars-bottts-sprites');
const { createAvatar } = require('@dicebear/avatars');

const network = 'Polygon' //Rinkeby  //can be: Mainnet, Polygon, Binance

export default class NFTBlokyContent extends Component {
  wallet = this.props.wallet
  nft = this.props.nft
  contract = this.props.contract
  nftName = this.props.nftName
  token = this.props.token
  svgAvatar = createAvatar(avatars_bottts_sprites, {seed: `${this.nft}${this.nftName}`, size: 200})

  state = {
    __html: ""
  }

  clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    alert('Complete Cache Cleared')
  };

  updateGameState(gameState) {
    console.log(`update game state: ${gameState}`)
  }

   loadScriptWithCode(scriptText) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptText;
    document.body.appendChild(script);
   }
  
  getColors(seed) {
    const random = seedrandom(seed);
    //const random = new Math.seedrandom(gameCustomizationParams.seed);

    var r = Math.floor(random() * 256);
    var g = Math.floor(random() * 256);
    var b = Math.floor(random() * 256);
    return [r,g,b]
  }

    createLevel = async () => {
    const res = await  fetch(
      `http://localhost:4000/api/game/nftbloky/create-level/${this.nft}/${this.wallet.toLowerCase()}`,
      {
        headers: {
          'Authorization': this.token
        }, 
      }
    );
    //console.log(`createLevel:${res}`)
      let result = await res.json()
      return result 
  }

  /*
   *  Any scripts loaded this way is exposed to public
  */
  loadScript() {
   // just a test; not secure
    this.loadScriptWithCode(`
      function updateState(e){
        console.log('you called update:'+e.blocks)
        updateGameState()        
      }
    `
    ) 

    // install a callback:
    // use website instead of backend to deliver the files
   //not used anymore
    let urls = [
      {id:'seedrandom',param: '',url:'https://plaable.com/games/scripts/seedrandom.js'},
      {id:'util', param:'',url:'https://plaable.com/games/scripts/util.js'},
      // {id:'canvg', param:'',url:'https://unpkg.com/canvg@3.0.4/lib/umd.js'},
    //  {id:'avatar',param:'',url:'https://cdn.jsdelivr.net/npm/@dicebear/avatars@4.10.1/dist/index.umd.min.js'},
    //  {id:'bott', param:'', url:'https://cdn.jsdelivr.net/npm/@dicebear/avatars-bottts-sprites@4.10.1/dist/index.umd.min.js'},
      //{ id: 'gameJS', wallet: this.wallet, nft: this.nft, url: 'http://localhost:3000/NFTBloky/scripts/game.js'},
      {
        id: 'gameJS', wallet: this.wallet, nft: this.nft, url: 'http://localhost:3000/games/NFTBloky/scripts/game2.js' /*'https://plaable.com/games/NFTBloky/scripts/game.js'*/,
        callback: (e) => {
         //this.updateState(e)
          console.log('called from gamejs')
       }
      },
    ]
    
      const div = document.getElementById('game') //document.createElement('div');
    
      for(var i=0;i<urls.length-1;i++) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = urls[i].url;
        if(i==urls.length-1) {
          tag.setAttribute('wallet', urls[i].wallet)
          tag.setAttribute('nft', urls[i].nft)
          tag.setAttribute('avatar', this.svgAvatar)
          tag.setAttribute('callback', urls[i].callback)
        }
        //tag.param = urls[i].param
        tag.id = urls[i].id
        div.appendChild(tag)  
      }
      //document.getElementsByTagName('body')[0].appendChild(div);
      //div.appendChild
  }
  
  loadGame() {
    console.log('calling nftBloky')
    let gameParams = {
      wallet: this.wallet,
      network: network,
      contract: contracts[network],
      nft: this.nft,
      avatar: this.svgAvatar      
    }

    nftBloky.nftBloky(gameParams, (e) => {
      console.log('callback from the game here ' + e)
      if (e === 'getColors') {
        return this.getColors(this.nft)
      } else if (e === 'createLevel') {
        console.log('calling reateLevel')
        return this.createLevel()
      }
    })    
  }
    
  componentWillMount() {
    console.log(`nft:${this.props}`)
    // fetch the HTML fragment with a local API request
    fetch(`https://playablenft-backend.onrender.com/NFTBloky/index.html`)  //${this.props.content  http://plaable.com/games/NFTBloky/index.html
      .then(resp => {
        // fetch returns a readable stream, so translate it into stringified HTML
        return resp.text();
      })
      .then(content => {
        // dangerouslySetInnerHTML requires using an object with an `__html` key
        this.setState({
          __html: content
        });
        //this.clearCacheData()
        //this.loadScript()
        this.loadGame()
        this.forceUpdate()
      })
      .catch(err => {
        // handle the error
      });
  }

  render() {
    return (
        <div dangerouslySetInnerHTML={this.state}>
          {
          //loadScript('https://playablenft.herokuapp.com/NFTBloky/seedrandom.js')
          }
          {
          //loadScript('https://playablenft.herokuapp.com/NFTBloky/util.js')
          }
          {
          //loadScript('https://playablenft.herokuapp.com/NFTBloky/game.js')
          }
{/*                  <script type="text/javascript" src="http://playablenft.herokuapp.com/NFTBloky/rough.js"></script>
        <script type="text/javascript" src="https://pixijs.download/release/pixi.js"></script>
        <script type="text/javascript" src="http://playablenft.herokuapp.com/NFTBloky/seedrandom.js"></script>
       <script type="text/javascript" src='http://playablenft.herokuapp.com/NFTBloky/util.js'></script> 
       <script type="text/javascript">
           const wallet1 = '0x1234567'
           const wallet2 = '0x9876543'
       </script>
        <script src="game.js"></script>
  <script type="text/javascript" src='https://unpkg.com/zdog@1/dist/zdog.dist.js'></script>        
    */}
        </div>        
    );
  }
}
