import {
    ChakraProvider, useDisclosure, Container, Box, Heading, Text, Image, Icon, Stack,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    List, ListItem
} from "@chakra-ui/react";
import theme from "../theme";
import Layout from "../components/Layout";
import Center from "../components/Center";
import "@fontsource/inter";
import NavBar from "../components/NavBar";
import NFTProvider from "../hook/NFTProvider";
import Footer from "../components/Footer"
import ScoreTable from "./ScoreTable";
import YoutubeVideoLink from "./YoutubeVideoLink";
import YoutubeVideoEmbed from "./YoutubeEmbedded";

function HowToPlay() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <ChakraProvider theme={theme}>
            <Layout>
                <NavBar />
                <Container maxW={'5xl'}>
                    <Stack
                        direction={'column'}
                        align={'center'}
                        spacing={{ base: 8, md: 10 }}
                        py={{ base: 10, md: 15 }}
                        mb={40}>
                        <Image src='/PlayableNFTSeries110Game.gif' width={270} height={480}></Image>
                        <Heading
                            textAlign={'center'}                            
                            fontWeight={600}
                            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                            lineHeight={'110%'}>
                            How to Play
                        </Heading>
                        <Text textAlign={'left'}>Bloky is a mini puzzle game. Youu need to fill the bag with all blocks. You can double click a block to rotate it for a desired orientation.</Text>
                            <Box p={5} shadow='md' borderWidth='1px'>
                                <Heading fontSize='xl' textColor={'orange.400'} >Mode</Heading>
                                <Text mt={4}>There are to basic modes of play: Mission and Timed. Your earn mining credits for Mission and token credits for Timed challenges.</Text>
                            </Box>
                        <Stack direction={'row'} >
                            <Box p={5} shadow='md' borderWidth='1px'>
                                <Heading fontSize='xl' textColor={'orange.400'}>Mission</Heading>
                                <Text mt={4}>In this mode, you have unlimited time to finish the puzzle. The difficulty level increases as you play along. You eran mining creadits after finishing each mission.</Text>
                            </Box>
                            <Box p={5} shadow='md' borderWidth='1px'>
                                <Heading fontSize='xl' textColor={'orange.400'}>Timed Challenge</Heading>
                                <Text mt={4}>In this mode, you have 120 seconds to finish the puzzle. The difficulty level does not change. You eran token creadits after finishing each timed level within the given time. </Text>
                            </Box>
                        </Stack>
                        {/* <YoutubeVideoLink /> */}
                        <YoutubeVideoEmbed/>
                        <ScoreTable/>
                        </Stack>
                </Container>
                
                <Footer />
            </Layout>
        </ChakraProvider>
    );
}

export default HowToPlay;
