import {
    ChakraProvider, useDisclosure, Container, Box, Heading, Text, Image, Icon, Stack,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    List, ListItem
} from "@chakra-ui/react";
import theme from "../theme";
import Layout from "../components/Layout";
import Center from "../components/Center";
import "@fontsource/inter";
import NavBar from "../components/NavBar";
import NFTProvider from "../hook/NFTProvider";
import Footer from "../components/Footer"


function ScoreTable() {


    return (
        <>
        <Table variant='striped' colorScheme='teal'>
            <TableCaption placement="top" fontSize={20} textColor={'orange.400'} >Mission Levels and Mining Credits</TableCaption>
            <Thead>
                <Tr>
                    <Th>Level</Th>
                    <Th isNumeric>Missions</Th>
                    <Th isNumeric>Daily Max*</Th>
                    <Th isNumeric>Mining Credits</Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr>
                    <Td>0</Td>
                    <Td isNumeric>25</Td>
                    <Td isNumeric>25</Td>
                    <Td isNumeric>1</Td>
                </Tr>
                <Tr>
                    <Td>1</Td>
                    <Td isNumeric>400</Td>
                    <Td isNumeric>30</Td>
                    <Td isNumeric>2</Td>
                </Tr>
                <Tr>
                    <Td>2</Td>
                    <Td isNumeric>400</Td>
                    <Td isNumeric>30</Td>
                    <Td isNumeric>3</Td>
                </Tr>
                <Tr>
                    <Td>3</Td>
                    <Td isNumeric>100</Td>
                    <Td isNumeric>20</Td>
                    <Td isNumeric>5</Td>
                </Tr>
            </Tbody>
            <Tfoot>
                <Tr>
                    <Th>Total 4</Th>
                    <Th isNumeric>925</Th>
                    <Th isNumeric textColor={'red.300'}>* Max mission can be played daily</Th>
                    <Th isNumeric></Th>
                </Tr>
            </Tfoot>
        </Table>

        <Table variant='striped' colorScheme='teal'>
            <TableCaption placement="top" fontSize={20} textColor={'orange.400'} >Timed Challenge and ERC20 Token Credits</TableCaption>
            <Thead>
                <Tr>
                    <Th>Level</Th>
                    <Th >Unlimited</Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr>
                    <Td>Time Limit Per Game</Td>
                    <Td>120 seconds</Td>
                </Tr>
                <Tr>
                    <Td>Trial*</Td>
                    <Td >3 tries/game</Td>
                </Tr>
                <Tr>
                    <Td>Score</Td>
                    <Td >8 pts</Td>
                </Tr>
                <Tr>
                    <Td>Score Bonus**</Td>
                    <Td >1 pt every 10 seconds finishing earlier than 120 seconds</Td>
                </Tr>
                    <Tr>
                        <Td>Max Score Bonus</Td>
                        <Td >5 pts for 50 seconds finishing earlier than 120 seconds</Td>
                    </Tr>
            </Tbody>
            <Tfoot>
                <Tr>
                    <Th textColor={'red.300'}>* After 3 failures for the challenge, your points will be deducted  by 8.</Th>
                    <Th textColor={'red.400'}>** If ypu finish earlier than 120 seconds, you eran extra points.</Th>
                </Tr>
            </Tfoot>
        </Table>
        </>
    )
}


export default ScoreTable;
