import {
    ChakraProvider, useDisclosure, Container, Box, Heading, Text, Image, Icon, Stack,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    List, ListItem, OrderedList, UnorderedList
} from "@chakra-ui/react";
import theme from "../theme";
import Layout from "../components/Layout";
import Center from "../components/Center";
import "@fontsource/inter";
import NavBar from "../components/NavBar";
import NFTProvider from "../hook/NFTProvider";
import Footer from "../components/Footer"
import ScoreTable from "./ScoreTable";
import YoutubeVideoLink from "./YoutubeVideoLink";

function Faq() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <ChakraProvider theme={theme}>
            <Layout>
                <NavBar />
                <Container maxW={'5xl'}>
                    <Stack
                        direction={'column'}
                        align={'left'}
                        spacing={{ base: 8, md: 10 }}
                        py={{ base: 10, md: 15 }}
                        mb={40}>
                        <Heading
                            textAlign={'left'}
                            fontWeight={600}
                            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                            lineHeight={'110%'}>
                            FAQ
                        </Heading>
                        <Stack direction={'column'} mb={10}>
                            <Heading fontWeight={400}
                                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                            >What is PlayableNFT</Heading>
                            <Text textAlign={'left'}>PlayableNFT(tm) is a series mini games minted a NFT so only a owner of the NFT can play and enjoy the game.</Text>
                        </Stack>
                        <Stack direction={'column'} mb={10}>
                            <Heading fontWeight={400}
                                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                            >What is PlayableNFT Series 1</Heading>
                            <Text textAlign={'left'}>Series 1 is a puzzle game called Bloky. We are planning to produce more series in the future, and each series will be a different game.</Text>
                        </Stack>

                        <Stack direction={'column'} mb={10}>
                            <Heading fontWeight={400} 
                                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                                >What is Bloky</Heading>
                            <Text textAlign={'left'}>Bloky is a mini puzzle game minted as NFT. Youu need to fill the bag with all blocks. You can double click a block to rotate it for a desired orientation.</Text>
                        </Stack>
                        <Stack direction={'column'} mb={10}>
                            <Heading fontWeight={400}
                                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                            >What is the address of PlayableNFTSeries 1</Heading>
                            <Text textAlign={'left'}>On Polygon Mainnet: 0xfbe489e731cA73256A466BA54E60f38b0074B962</Text>
                        </Stack>
                        <Stack direction={'column'} mb={10}>
                            <Heading fontWeight={400}
                                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                            >Once I have owned a PlayableNFT(tm) how do I access it on my PC</Heading>
                            <Text textAlign={'left'}>In Chrome or FireFox with MetaMask or any other wallet extension installed, browse to https://plaable.com , and click on "Connect to a wallet" buutton. Your Playable NFT will be listed automaticlly on the web page. Here is a example: </Text>
                            <Image src="/plaable_nft_list.png"></Image>
                            <Text>To play the game click on the NFT, you will be directed to the game.</Text>
                        </Stack>

                        <Stack direction={'column'} mb={10}>
                            <Heading fontWeight={400}
                                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                            >How do I add my Playable NFT to my wallet</Heading>
                            <Text textAlign={'left'}>Make sure your wallet support Polygon and adding the NFT youu own is the same as adding any other NFT to your wallet. The following steps show how to do it with Meta Mask's mobile wallet:</Text>
                            <OrderedList pl={10}>
                                <ListItem>Select Polygon Mainnet</ListItem>
                                <ListItem>Select NFT tab in your account and enter the contract address</ListItem>
                                <ListItem>Enter ID of your NFT</ListItem>
                            </OrderedList>
                            <Box align={'center'}><Image src="/mobile_wallet_add_nft.jpeg" alt="add playable nft to metamask mobile wallet" width={293} height={633} ></Image></Box>
                        </Stack>
                        <Stack direction={'column'} mb={10}>
                            <Heading fontWeight={400}
                                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                            >How to add polygon to MetaMask Mobile Wallet App</Heading>
                            <Text textAlign={'left'}></Text>
                            <Box align={'center'}><Image src="/how_to_add_polygon.jpeg" width={293} height={634}></Image></Box>
                            <Text>You can probably find a lot of info on this subject, and here is a short instruction: go to side menu, select "Settings->Networks->Add Network", enter the above parameters in MetaMask Mobile Wallet App:</Text>
                        </Stack>
                        <Stack direction={'column'} mb={10}>
                            <Heading fontWeight={400}
                                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                            >How to play Bloky NFT game on mobile phone </Heading>
                            <Text textAlign={'left'}></Text>
                            <Box align={'center'}>
                                <Image src="/metamask_browser2.png" width={293} height={634} />
                                <Image src="/plaable_website.png" width={293} height={634} />
                            </Box>
                            <Text>A mobile wallet like MetaMask is required to play PlayableNFT games. Make sure you have MetaMask Mobile App installed with polygon network configured and selected. Go to Side Menu and select Browser, enter https://plaable.com. Once on plaable.com site, if your wallet is not connected, cilck on "Connect to the wallet" button, once connected, your PlayableNFT will show up on the list, then select your NFT to start playing the game.</Text>
                        </Stack>

                    </Stack>
                </Container>

                <Footer />
            </Layout>
        </ChakraProvider>
    );
}

export default Faq;
