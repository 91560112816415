import {
    Badge, LinkBox, Box, LinkOverlay, Text, Image, Icon, Stack, Container,

} from "@chakra-ui/react";

import { StarIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

const youtube_url = 'https://youtu.be/LsTaxTw894k'

function gotoYoutube() {
    window.open(youtube_url)
}
export default function YoutubeVideoEmbed(url) {
    return (
        <Container>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/LsTaxTw894k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Container>
    )
}